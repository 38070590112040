html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    background: #eee;
    color: #111;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif; */

    /* font-family: "Roboto", sans-serif; */
    font-family: "Open Sans", sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.container {
    background-color: #fff;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    /* -webkit-box-shadow: 0px 10px 54px -11px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 10px 54px -11px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 10px 54px -11px rgba(0, 0, 0, 0.75); */
}

body * {
    transition: all 0.3s ease-in-out;
}

/*------------- NAVIGATION BAR -------------------*/

nav {
    position: fixed;
    z-index: 99;
    background: #f7f7f7;
    width: 100%;
    max-width: 1400px;
    margin: 0;
    display: grid;
    border-bottom: 4px solid #fcc200;

    grid-template-columns: 1fr 1fr;
    text-transform: uppercase;

    -webkit-box-shadow: 0px 10px 54px -11px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 10px 54px -11px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 10px 25px -11px rgba(0, 0, 0, 0.75);
}

nav ul {
    width: 100%;
    display: grid;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
}

.logo {
    color: #ff7300;
    /* padding-left: 30px; */
    height: 100px;
}

.companySubtitle {
    font-size: 1rem;
}

nav ul {
    padding: 0px;
    margin: 0px;
}
nav ul:first-of-type {
    grid-template-columns: 1fr;
}
nav ul:first-of-type li {
    font-size: 2rem;
    font-weight: bold;
    /* text-indent: 30px; */
}
nav ul:first-of-type li span {
    font-weight: 300;
    color: #002061;
    margin: 0;
    /* font-style: italic; */
}

nav ul:last-of-type {
    display: flex;
    justify-content: flex-end;
    color: #444;
}

nav ul li {
    margin-right: 5px;
}

nav ul li a {
    color: #002061;
    text-decoration: none;
    font-weight: bold;
    display: block;
    padding: 10px 30px;
    border-radius: 0px;
    /* border: 1px solid transparent; */
}
nav ul li a:hover {
    /* border: 1px solid gray; */
    background: #fcc200;
    /* color: white; */
}

/*------------- HEADER -------------------*/

header {
    background: url("./assets/enter-key.png");
    background-color: rgba(00, 00, 00, 0.5);
    background-size: 100%;
    height: 700px;
    background-position: center;
    position: relative;
}

header div.head {
    position: relative;
    z-index: 2;
    display: grid;
    width: 70%;
    /* grid-template-columns: repeat(2, 1fr); */
    margin: 0 auto;
    padding-top: 20%;
    align-items: center;
    color: #fff;
}

/*------------- MAIN SECTION -------------------*/

main {
    position: relative;
    z-index: 3;
    overflow: hidden;
}

main section {
    width: 70%;
    padding: 130px 0 0px 0;
    margin: 10px auto 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

main section h2 {
    margin: 0;
    font-size: 3rem;
    color: #fcc200;
    text-transform: uppercase;
    line-height: 3rem;
    padding-right: 10px;
}

main section div {
    position: relative;
}

main section p {
    width: 100%;
    color: gray;
    text-align: center;
}

main section div p {
    font-size: 20px;
    font-weight: 300;
    color: gray;
    text-align: left;
}

/*------------- SERVICES -------------------*/

div.services {
    display: grid;
    height: auto;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px;
}

.services div {
    text-align: center;
    background: #fff;
    width: 90%;
    margin: 10px auto 50px auto;
    color: gray;

    -webkit-box-shadow: 0px 5px 10px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 5px 30px -9px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 5px 30px -9px rgba(0, 0, 0, 0.75);
}

.services div div p {
    width: 90%;
}
.services img {
    object-fit: cover;
    width: 20%;
    margin: auto;
    padding-top: 20px;
    filter: grayscale(50%);
}

.services p.service-icon {
    object-fit: cover;
    height: auto;
    text-align: left;
    color: rgb(0, 99, 248);
    filter: grayscale(0%);
}
.service-title {
    font-size: 2rem;
    height: 4rem;
    margin: 0;
    border-bottom: 2px solid #002061;
    color: #fcc200;
    font-weight: 600;
    text-align: center;
}

.service-info {
    font-size: 16px;
}
.services p:last-of-type {
    padding: 20px 60px;
    text-align: left;
}

.locationMap {
    width: 100%;
}

/*------------- WhoWeAre -------------------*/
.whoWeAre {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding-top: 50px;
}

.whoWeAreImg {
    /* height: 200px; */
    vertical-align: middle;
    align-items: center;
}

.enterTally {
    box-sizing: border-box;
    /* box-shadow: 0 0 10px 2px #fcc200 ; */
    box-shadow: 0 0 10px 2px #002061;
    box-shadow: 0 0 10px 2px darkgray;
    padding: 0 20px;
    margin: 40px 0 0 0;
}
.enterTallyImagesWrap {
    display: flex;
}
.enterTallyImages {
    height: 200px;
    flex: 1;
}
.enterTallyImagesEmpty {
    flex: 1;
}

/*------------- FOOTER -------------------*/

footer {
    /* background-color: darkgray; */
    background-color: #002061;
    color: #fff;
    padding: 20px 20px;
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
}

footer ul {
    list-style: none;
    display: flex;
    align-items: flex-end;
    padding: 0;
    margin-left: auto;
    padding-right: 40px;
}

.footerAddress {
    padding-left: 40px;
}

footer h3 {
    text-align: center;
}

footer ul {
    align-items: flex-end;
}
footer ul li a img {
    color: #eee;
    width: 24px;
    height: 24px;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    margin: 10px;
}

td {
    padding: 10px 20px;
}

/*------------- MEDIA QUERIES -------------------*/

@media (max-width: 1310px) and (min-width: 1100px) {
    /* nav {
        text-align: center;
        display: grid;
        grid-template-columns: 1fr;
    } */

    nav ul li a {
        color: #002061;
        text-decoration: none;
        font-weight: bold;
        display: block;
        padding: 10px 10px;
        border-radius: 10px;
        /* border: 1px solid transparent; */
    }
    header div.head {
        width: 75%;
        padding-top: 190px;
    }
    header div.head h1 {
        font-size: 4rem;
    }

    main section {
        width: 80%;
        padding: 90px 0;
        margin: 0px auto 50px auto;
        display: flex;
        flex-direction: column;
        /* grid-template-columns: 1fr; */
    }

    main section h2 {
        padding: 90px 0 0 0;
    }
    main section div p {
        box-sizing: border-box;
        padding: 0px 90px;
    }

    .services p.service-icon {
        font-size: 2.4rem;
        padding: 10px 30px;
        margin: 0px 0px 30px 0px;
    }
    .services p.service-title {
        font-size: 1.5rem;
        padding: 0;
        margin: 0;
    }

    .services p:last-of-type {
        padding: 0px 20px;
        text-align: left;
    }

    .gallery {
        height: 600px;
    }
}

@media (max-width: 1099px) and (min-width: 901px) {
    header {
        height: 500px;
        background-position: bottom;
    }

    nav ul li a {
        color: #002061;
        text-decoration: none;
        font-weight: bold;
        display: block;
        padding: 10px 10px;
        border-radius: 0px;
        /* border: 1px solid transparent; */
    }

    header div.head {
        width: 75%;
        padding-top: 400px;
        grid-template-columns: 1fr;
    }
    header div.head h1 {
        font-size: 4rem;
    }

    main section {
        width: 90%;
        margin: 0px auto 50px auto;
        display: flex;
        flex-direction: column;
        /* grid-template-columns: 1fr; */
    }

    main section div p {
        box-sizing: border-box;
        padding: 0px 90px;
    }

    div.services {
        display: grid;
        grid-template-columns: 1fr;
    }
    div.services div {
        width: 80%;
    }

    .services p.service-icon {
        font-size: 2.4rem;
        padding: 10px 30px;
        margin: 0px 0px 30px 0px;
    }
    .services p.service-title {
        font-size: 2.1rem;
        padding: 0;
        margin: 0;
    }

    .services p:last-of-type {
        padding: 0px 20px;
        text-align: left;
    }
}

/* @media (max-width: 8px) and (min-width: 720px) {
    nav ul li a {
        color: #444;
        text-decoration: none;
        font-weight: bold;
        display: block;
        padding: 10px 5px;
        border-radius: 10px;
        border: 1px solid transparent;
    }
    header {
        height: 440px;
        background-size: 160%;
    }

    header div.head {
        width: 75%;
        padding-top: 120px;
        grid-template-columns: 1fr;
    }
    header div.head h1 {
        font-size: 3rem;
    }

    main section {
        width: 90%;
        padding: 150px 0;
        margin: 0px auto 50px auto;
        display: grid;
        grid-template-columns: 1fr;
    }
    main section div p {
        box-sizing: border-box;
        padding: 0px 90px;
    }

    div.services {
        display: grid;
        grid-template-columns: 1fr;
    }
    div.services div {
        width: 70%;
    }

    .services p.service-icon {
        font-size: 2.4rem;
        padding: 10px 30px;
        margin: 0px 0px 30px 0px;
    }
    .services p.service-title {
        font-size: 2.1rem;
        padding: 0;
        margin: 0;
    }

    .services p:last-of-type {
        padding: 0px 20px;
        text-align: left;
    }

    .gallery {
        height: 400px;
    }
} */

@media (max-width: 900px) and (min-width: 0px) {
    .logo {
        /* color: #ff7300; */
        /* padding-left: 30px; */
        height: 50px;
    }
    nav {
        text-align: center;
        display: grid;
        grid-template-columns: 1fr;
    }
    nav ul li a {
        color: #002061;
        text-decoration: none;
        font-weight: bold;
        font-size: 12px;
        display: block;
        padding: 10px 5px;
        border-radius: 10px;
        /* border: 1px solid transparent; */
    }

    nav ul {
        margin-bottom: 10px;
    }
    nav ul:first-of-type {
        border-bottom: 1px solid #444;
        padding-bottom: 10px;
        /* text-align: center; */
    }

    nav ul:last-of-type li {
        text-align: center;
        width: 100%;
        /* padding-top: 10px; */
    }

    header {
        background-size: 200%;
        height: 450px;
        position: relative;
        object-fit: fill;
    }

    header div.head {
        width: 75%;
        grid-template-columns: 1fr;
        padding-top: 260px;
    }
    header div.head h1 {
        font-size: 2.3rem;
        border: none;
    }

    header div.head p {
        padding-left: 0px;
    }

    main section:first-of-type {
        /* margin-top: 30px; */
    }

    main section {
        width: 80%;
        padding: 160px 0 0 0;
        margin: 30px auto 0px auto;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    main section h2 {
        font-size: 2rem;
    }
    main section div p {
        box-sizing: border-box;
        padding: 0px 10px;
        font-size: 1rem;
        text-align: start;
    }

    div.services {
        display: grid;
        grid-template-columns: 1fr;
    }
    div.services div {
        width: 80%;
    }

    .services p.service-icon {
        font-size: 2.4rem;
        padding: 10px 30px;
        margin: 0px 0px 30px 0px;
    }
    .services p.service-title {
        font-size: 1.5rem;
        height: 4.5rem;
        padding: 0;
        margin: 0;
    }

    .services p:last-of-type {
        padding: 0px 20px;
        text-align: left;
    }

    .whoWeAre {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        /* padding-top: 50px; */
    }

    .whoWeAreImg {
        margin: auto;
        height: 150px;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
    }

    .enterTallyImagesWrap {
        display: flex;
        flex-direction: column;
        /* height: 300px; */
    }

    .enterTallyImages {
        width: 100%;
        height: auto;
        flex: 0;
    }
    .enterTallyImagesEmpty {
        flex: 0;
    }

    footer {
        padding: 20px 0;
        text-align: center;

        grid-template-columns: 1fr;
    }
    footer ul li a {
        font-size: 1.8rem;
    }

    .footerAddress {
        padding-left: 0px;
    }
    footer ul {
        list-style: none;
        display: flex;
        align-items: flex-end;
        padding: 0;
        margin: auto;
        /* padding-right: 40px; */
    }
}
